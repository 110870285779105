import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div className="conteudo_geral">
          <noscript
            aria-hidden="true"
            dangerouslySetInnerHTML={{
              __html:
                '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TQDRJ93" height="0" width="0" style="display:none;visibility:hidden" title="Google Tag Manager">Google Tag Manager</iframe>'
            }}
          />
          <div id="head_geral" className="container">
            <div className="container">
              <header id="head" role="banner">
                <div className="five columns alpha">
                  <div className="bisnaga" />
                </div>
                <div className="menu_linha_cima" />
                <div className="menu_linha_abaixo" />
                <div
                  className="eleven columns omega"
                  style={{
                    float: "right"
                  }}
                >
                  <div className="cabecalho_esq">
                    <div className="div_logo">
                      <a href="/" title="Início">
                        <img
                          className="img_logo"
                          src="/sites/default/files/color/responsive/logo.png"
                          alt="Início"
                        />
                      </a>{" "}
                    </div>
                  </div>
                  <nav id="navigation" role="navigation">
                    <div id="main-menu">
                      <ul className="menu">
                        <li className="first leaf">
                          <a href="/o-que-e-biafine">O que é biafine®</a>
                        </li>
                        <li className="leaf">
                          <a href="/quando-e-como-aplicar">
                            Quando e como <br/> aplicar
                          </a>
                        </li>
                        <li className="leaf">
                          <a href="/como-funciona">Como funciona</a>
                        </li>
                        <li className="leaf">
                          <a href="/balsamo-multi-reparador-apaziguante"><span className="blue-text">Cica</span> biafine® <br/><span className="align-leaf">Cosmético</span></a>
                        </li>
                        <li className="last leaf">
                          <a href="/contactos">Contactos</a>
                        </li>
                      </ul>{" "}
                    </div>
                  </nav>
                </div>
              </header>
            </div>
          </div>
          <div className="container" id="content-contain">
            <div className="menu_shadow" />
            <div id="content" className="sixteen columns">
              <div id="breadcrumbs">
                <h2 className="element-invisible">Está aqui</h2>
                <nav className="breadcrumb">
                  <a href="/">Início</a> » Aviso legal
                </nav>
              </div>
              <section id="post-content" role="main">
                <h1 className="page-title">Aviso legal</h1>
                <div
                  style={{
                    height: "450px",
                    overflow: "scroll",
                    "overflow-x": "hidden"
                  }}
                >
                  <div className="region region-content">
                    <div id="block-system-main" className="block block-system">
                      <div className="content">
                        <span
                          property="dc:title"
                          content="Aviso legal"
                          className="rdf-meta element-hidden"
                        />
                        <span
                          property="sioc:num_replies"
                          content="0"
                          datatype="xsd:integer"
                          className="rdf-meta element-hidden"
                        />
                        <div className="content node-page">
                          <div className="field field-name-body field-type-text-with-summary field-label-hidden">
                            <div className="field-items">
                              <div
                                className="field-item even"
                                property="content:encoded"
                              >
                                <div>
                                  <p className="textoBlocoInicialCinza">
                                    Ao aceder e consultar este site concorda sem
                                    restrições com as seguintes condições
                                    <br />
                                    de uso:
                                  </p>
                                  <p className="textoBlocoCinzaEscuro">
                                    Todo o conteúdo deste site da Internet é
                                    propriedade ou é controlado pela Johnson
                                    &amp; Johnson Limitada e está protegido
                                    pelas leis internacionais do copyright. Pode
                                    fazer download do conteúdo apenas para seu
                                    uso pessoal para fins não comerciais, mas
                                    não são permitidas a modificação ou
                                    posterior reprodução do conteúdo. De outro
                                    modo, o conteúdo não pode ser copiado ou
                                    usado de qualquer forma.
                                  </p>
                                  <p className="textoBlocoCinzaEscuro">
                                    Os proprietários deste site farão os
                                    possíveis para incluir informação atualizada
                                    e exata neste site da Internet, mas não
                                    apresentam declarações, garantias ou
                                    certezas quanto à exatidão, aceitação, ou
                                    integridade da informação disponibilizada.
                                    Os proprietários deste site não poderão ser
                                    responsabilizados por quaisquer danos ou
                                    prejuízos resultantes do seu acesso, ou
                                    incapacidade de acesso, a este site da
                                    Internet, ou da sua dependência em relação a
                                    qualquer informação fornecida neste site da
                                    Internet. Este site destina-se a residentes
                                    de Portugal.
                                  </p>
                                  <p className="textoBlocoCinzaEscuro">
                                    Este site da Internet pode apresentar links
                                    ou referências a outros sites mas os
                                    proprietários deste site não têm qualquer
                                    responsabilidade pelo conteúdo de sites de
                                    Terceiros e não poderão ser
                                    responsabilizados por quaisquer danos ou
                                    prejuízos resultantes desses conteúdos.
                                    Quaisquer links para outros sites são
                                    apresentados meramente como uma comodidade
                                    para os utilizadores deste site da Internet.
                                  </p>
                                  <p className="textoBlocoCinzaEscuro">
                                    As marcas, marcas de serviços, nomes
                                    comerciais, identidade visual e produtos
                                    apresentados neste site da Internet estão
                                    protegidos em Portugal e internacionalmente.
                                    Não pode ser feito nenhum uso de qualquer um
                                    destes sem a prévia autorização por escrito
                                    dos proprietários deste site, exceto para
                                    identificar os produtos ou serviços da
                                    empresa.
                                  </p>
                                  <p className="textoBlocoCinzaEscuro">
                                    Qualquer informação pessoal identificável em
                                    comunicações eletrónicas para este site é
                                    regulada pela Política de Privacidade do
                                    mesmo. Os proprietários deste site serão
                                    livres de usar ou copiar todas as outras
                                    informações em tais comunicações, incluindo
                                    quaisquer ideias, invenções, conceitos,
                                    técnicas ou conhecimentos ali divulgados,
                                    para quaisquer fins. Tais fins podem incluir
                                    a divulgação a terceiros e/ ou o
                                    desenvolvimento, fabrico e/ ou a
                                    comercialização de bens e serviços.
                                  </p>
                                  <p className="textoBlocoCinzaEscuro">
                                    O remetente de quaisquer comunicações para
                                    este site da Internet ou, de outro modo,
                                    para os proprietários deste site será
                                    responsável pelo conteúdo e informações ali
                                    contidas, incluindo a sua autenticidade e
                                    exatidão.
                                  </p>
                                  <p className="textoBlocoCinzaEscuro">
                                    O conteúdo deste site destina-se a ser um
                                    serviço para os seus visitantes. Em qualquer
                                    altura a Johnson &amp; Johnson Limitada será
                                    livre de corrigir este site sem aviso
                                    prévio.
                                  </p>
                                </div>
                                <p>&nbsp;</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <footer></footer>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div className="clear" />
          </div>
          <div id="copyright_geral" className="container">
            <div id="copyright" className="container">
              <div className="background_img_interior" />
              <div className="rodape_esq">
                <div className="region region-footer">
                  <div id="block-block-1" className="block block-block">
                    <div className="content">
                      <p>
                        www.biafine.pt © Johnson &amp; Johnson Limitada.
                        PT/BIA/16-1068
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="rodapeDir rodape_dir">
                <div className="region region-footer-menu">
                  <div
                    id="block-menu-menu-menu-rodape"
                    className="block block-menu"
                  >
                    <div className="content">
                      <ul className="menu">
                        <li className="first leaf">
                          <a href="/politica-de-privacidade" title>
                            POLÍTICA DE PRIVACIDADE
                          </a>
                        </li>
                        <li className="leaf">
                          <a href="/cookie-policy" title>
                            POLÍTICA DE COOKIES
                          </a>
                        </li>
                        <li className="leaf active-trail">
                          <a
                            href="/aviso-legal"
                            title
                            className="active-trail active"
                          >
                            AVISO LEGAL
                          </a>
                        </li>
                        <li className="leaf">
                          <a href="/mapa-do-site" title>
                            MAPA DO SITE
                          </a>
                        </li>
                        <li className="leaf">
                          <a href="/contactos" title>
                            CONTACTOS
                          </a>
                        </li>

                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="rodape_centro">
                <div className="region region-footer-center">
                  <div id="block-block-4" className="block block-block">
                    <div className="content">
                      <div>
                        Este site é publicado pela Johnson &amp; Johnson
                        Limitada única responsável pelo seu
                        conteúdo.&nbsp;Destina-se a utilizadores em Portugal.
                      </div>
                      <div>
                        V03 BIAFINE® 6,7 mg/g, emulsão cutânea. Trolamina.
                        Medicamento não sujeito a receita médica. Indicado em
                        queimaduras do 1º grau, eritema solar e feridas cutâneas
                        superficiais não infetadas. BIAFINE® não confere
                        proteção solar e deve ser afastado dos olhos. Não deve
                        ser utilizado como um produto cosmético em pele
                        saudável. Contém parabenos, propilenoglicol e sorbato de
                        potássio que podem originar reações cutâneas ou
                        alérgicas. Contraindicado em doentes com
                        hipersensibilidade à substância ativa ou a qualquer um
                        dos excipientes e em feridas com hemorragia e infetadas.
                        Leia cuidadosamente as informações constantes da
                        embalagem e do folheto informativo. Em caso de dúvida ou
                        de persistência dos sintomas consulte o seu médico ou
                        farmacêutico. Johnson &amp; Johnson Lda. Lagoas Park,
                        Edifício 9, 2740-262 Porto Salvo. NUIPC 500 153 370.
                        <br />
                        &nbsp;
                      </div>
                      <div>&nbsp;</div>
                      <div className="last leaf">
                        <a id="ot-sdk-btn" className="ot-sdk-show-settings">
                          Configuração de cookies
                          </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="clear" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Page;
